/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

.dev-panel-events table th,
td {
    max-width: none !important;
}

.router-wrapper {
    position: relative;
    overflow: hidden;
    width: 100vw;
    height: calc(100% - 47px);
    perspective: 1200px;
    transform-style: preserve-3d;
}

:host {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #60d7a9;
}

.center {
    display: flex;
    justify-content: center;
}

.spacebetween {
    display: flex;
    justify-content: space-between;
}

.aligncenter {
    display: flex;
    align-items: center;
}

.smallImage {
    width: 6rem;
    height: 6rem;

}

.carouselImage {
    width: 5rem;
}

.carouselSingle {
    width: 35%;
}

.smallbtn {
    width: 2.0rem;
    height: 1.5rem;
    padding: 0rem;
    margin: 0rem;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
}

.profile-picture-container {
    height: min-content;
    width: min-content;
}

input::placeholder {
    color: #a2a2a2 !important;
}

textarea::placeholder {
    color: #a2a2a2 !important;
}

.custom ngb-datepicker .ngb-dp-weekday {
    color: rgb(85, 107, 47);
}

.custom ngb-datepicker .ngb-dp-header .ngb-dp-arrow-btn {
    background-color: transparent;
    color: rgb(85, 107, 47);
}

.custom .ngb-dp-navigation-chevron {
    border-width: .25rem .25rem 0 0;
}

.korona-statistics ngb-datepicker .ngb-dp-weekday {
    color: rgba(0, 109, 56, 1);
}

.korona-statistics ngb-datepicker .ngb-dp-header .ngb-dp-arrow-btn {
    background-color: transparent;
    color: rgba(0, 109, 56, 1);
}

.korona-statistics .ngb-dp-navigation-chevron {
    border-width: .25rem .25rem 0 0;
}